.logo {
    width: 64px;
    height: 64px;
}

.selectedPayload {
    overflow-x: scroll;
}

.candidate-section {
    height: 55vh;
    overflow-y: scroll;
}

.text-end {
    text-align: end;
}

.candidate-section-message {
    height: 100%;
    max-height: 40vh !important;
}
